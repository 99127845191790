
import Vue from "vue";
import store from "@/store";
import rules from "@/services/helpers/validation-rules";
import { mask } from "vue-the-mask";

// Configs
import allowedFields from "../../../config/available-fields.json";

// Components
import ProductsPicker from "../../ProductsPicker.vue";
import Comments from "@/modules/orders/components/Comments.vue";
import ClientData from "@/modules/orders/components/ClientData.vue";
import Documents from "@/modules/orders/components/Documents.vue";

export default Vue.extend({
  name: "Create",

  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({})
    },
    items: {
      type: Object,
      required: true,
      default: () => ({})
    },
    errorMessages: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },

  components: {
    Comments,
    ProductsPicker,
    ClientData,
    Documents
  },

  directives: { mask },

  data: () => ({
    rules,
    allowedFields: allowedFields as any,
    selectedClient: null as null | SelectComponentInterface,
    selectedCarnet: null as null | SelectComponentInterface,
    model: {
      documents: [] as Array<any>,
      goods: [] as Array<any>,
      details: { countries: [] as Array<any> } as any,
      order: {
        exchange_rate: "1.0000",
        document_type: "original",
        source: "operator",
        release_mode: "normal",
        handler_id: 1,
        valid_form: new Date().toISOString().substr(0, 10)
      } as any
    } as any,
    oldModel: null as any,
    allDelegatesText: {
      ro: "Toate persoanele împuternicite",
      en: "All authorized persons",
      ru: "Все уполномоченные лица"
    } as any,
    selects: {
      countries: [] as any
    } as any,
    lang: store.getters["localization/getCurrent"],
    allDelegates: true as boolean,
    selectedTab: 2,
    forceKey: 0 as number,
    isShowCountries: {
      destination: false as boolean,
      transit: false as boolean
    } as any,
    currency: {} as any,
    showTooltip: false,
    comments: {} as any,
    oldComments: null as any
  }),

  watch: {
    value: {
      immediate: true,
      handler() {
        this.model = this.value;
      }
    },
    items: {
      immediate: true,
      handler() {
        this.comments = this.items;
      }
    }
  },

  computed: {
    isClassic() {
      return this.model.order.power_of_attorney === "classic";
    },
    user() {
      return store.getters["authentication/credentials"]?.user;
    },
    goodsSum() {
      let sum = 0;
      for (const item of this.model.details.countries) {
        if (item.quantity * item.price) {
          sum += item.quantity * item.price;
        }
      }
      return sum;
    },
    parentLanguage() {
      return this.model.order.language === "ro";
    },
    destinationCountries() {
      return this.model.details.countries.filter(
        (item: any) => item.scope === "destination"
      );
    },
    transitedCountries() {
      return this.model.details.countries.filter(
        (item: any) => item.scope === "transit"
      );
    }
  },

  methods: {
    async submit(): Promise<void> {
      const form = this.$refs.form as Vue;

      if (form.validate()) {
        this.$emit("submit");
      }
    },
    toggleTransitedCountries(): void {
      this.isShowCountries.transit = !this.isShowCountries.transit;
    },
    toggleDestinationCountries(): void {
      this.isShowCountries.destination = !this.isShowCountries.destination;
    },
    isCheckedCountry(country: any, scope: string): boolean {
      return this.model.details.countries?.some(
        (item: any) => item.country_id === country.value && item.scope === scope
      );
    },
    updateComments(event: any) {
      this.comments[event.key] = event;
      this.$emit("input", this.comments);
      this.changeKey();
    },
    changeKey() {
      this.forceKey = this.forceKey++;
    },
    loadDocuments(val: any) {
      this.model.documents = val;
    },
    setComment(val: any) {
      this.comments[val.key] = val;
      this.$forceUpdate();
    }
  }
});
